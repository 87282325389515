import {createStore, applyMiddleware, compose as origCompose, combineReducers} from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk/es/index.js';
import userManager from './utils/userManager';
import {createOidcMiddleware, reducer as oidcReducer} from '@competencegroup/redux-oidc-es6';
import {lazyReducerEnhancer} from 'pwa-helpers/lazy-reducer-enhancer.js';
import promiseMiddleware from 'redux-promise-middleware';
import minervaSettings from './reducers/minervaSettings.js';
import learningPlan from './learningplan/reducers/learningplan.js';
import learningPlanInfo from './learningplan/reducers/learningplanInfo.js';
import workflow from './learningplan/reducers/workflow.js';
import digitalLearning from './learningplan/reducers/digitallearning.js';
import catalogItem from './reducers/catalogItem.js';
import costregistration from './costregistration/reducers/costregistration.js';
import scormResult from './scorm/reducers/scorm-result.js';
import scorm from './scorm/reducers/scorm.js';
import catalog from './reducers/catalog.js';
import planningReport from './reducers/planningReport.js';
import signIn from './reducers/signin.js';
import adminCatalog from './reducers/adminCatalog.js';
import assignmentCatalog from './reducers/assignmentCatalog.js';
import ReduxAsyncQueue from 'redux-async-queue/es/index.js';
import skillsSettings from './reducers/skillsSettings.js';
import trainingsSettings from './reducers/trainingsSettings.js';
import accreditation from './reducers/accreditation.js';
import accreditationInfo from './reducers/accreditationInfo.js';
import registration from './reducers/registration.js';
import classroom from './reducers/classroom.js';
import skillsTargetIdMap from './reducers/skillsTargetIdMap.js';

// import consumer from './reducers/consumer.js';
// import startLti from './reducers/startLti.js';

import mySaga from './sagas/rootSaga.js';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || origCompose;

export const store = createStore(
    (state, action) => state,
    compose(
        lazyReducerEnhancer(combineReducers),
        applyMiddleware(
            sagaMiddleware,
            createOidcMiddleware(userManager),
            thunk,
            ReduxAsyncQueue,
            promiseMiddleware,
        ),
    ),
);

store.addReducers({
    signIn: signIn,
    oidc: oidcReducer,
    minervaSettings: minervaSettings,
    learningPlan: learningPlan,
    learningPlanInfo: learningPlanInfo,
    workflow: workflow,
    digitalLearning: digitalLearning,
    catalogItem: catalogItem,
    costregistration: costregistration,
    scormResult: scormResult,
    scorm: scorm,
    catalog: catalog,
    planningReport: planningReport,
    adminCatalog: adminCatalog,
    assignmentCatalog: assignmentCatalog,
    skillsSettings: skillsSettings,
    trainingsSettings: trainingsSettings,
    accreditation: accreditation,
    accreditationInfo: accreditationInfo,
    registration: registration,
    classroom: classroom,
    skillsTargetIdMap: skillsTargetIdMap,
});

sagaMiddleware.run(mySaga);
