/* eslint-disable prettier/prettier */
export const FETCH_LEARNINGPLAN_INFO = 'FETCH_LEARNINGPLAN_INFO';

const getHeaders = (user) => {
    return {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
    };
};

export const fetchLearningPlanInfo = (targetId) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const {portalApiUrl} = minervaSettings;

    if (!portalApiUrl) return;

    dispatch({
        type: FETCH_LEARNINGPLAN_INFO,
        async payload() {
            const params = new URLSearchParams({
                targetId: targetId,
                idString: oidc.user.profile.sub,
            });

            const res = await fetch(
                `${portalApiUrl}/api/1.0/learningplan/info?${params}`,
                {
                    headers: getHeaders(oidc.user),
                }
            );

            return await res.json();
        },
    });
};
