import {FETCH_LEARNINGPLAN_INFO} from '../actions/learningplanInfo';

const app = (state = {}, action) => {
    const {type, payload} = action;

    switch (type) {
        case `${FETCH_LEARNINGPLAN_INFO}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${FETCH_LEARNINGPLAN_INFO}_FULFILLED`: {
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        }
        case `${FETCH_LEARNINGPLAN_INFO}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};

export default app;
